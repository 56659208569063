import React from "react";
import { Typography } from "@mui/material";
import { Save, X } from "react-feather";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { GitBranch } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  discardFiles: () => void;
 *  saveFiles: () => void;
 *  activeBranch: string;
 *  unsavedFiles: Record<string, any>
 * }} data
 */
const UnsavedFilesModal = ({
  isShown,
  onHide,
  discardFiles,
  saveFiles,
  activeBranch,
  unsavedFiles,
  saving,
  switchBranch,
  newBranch,
}) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
      <GitBranch />{" "}
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          You are switching branch.
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
          <>
            You have unsaved models that will be lost if you switch branches. <br />
            Do you want to save them before switching branches?
            <ul>
              {Object.keys(unsavedFiles)
                .filter(file => !file.startsWith('untitled-'))
                .map(
                  (file) =>
                    file !== "+" && (
                      <React.Fragment key={file}>
                        - <b>{file}</b>
                        <br />
                      </React.Fragment>
                    )
                )}
            </ul>
          </>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="error" onClick={() => {
          discardFiles();
          onHide();
        }}>
          <X size={16} /> No, discard all
        </ModalLayoutBtn>

          <ModalLayoutBtn color="success" onClick={() => {
            saveFiles();
            onHide();
          }} disabled={saving}>
            <Save size={16} /> Yes, save them
          </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default UnsavedFilesModal;
