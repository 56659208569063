import React, { useState, useEffect } from "react";
import { ResizableBox } from "react-resizable";
import * as Icon from "react-feather";
import {
  Form,
  Button,
  Accordion,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DatajoltSpinner from "../global/DatajoltSpinner";
import "./SidebarModal.css";
import { Trash2 } from 'react-feather';
import { fetchAvaialbleTestsService, fetchColumnsFromEditorService, fetchTestsService, handleSaveTestsService } from "../../services/editor";
import {CircularProgress} from "@mui/material";

export default function DataQualityTestsCRUD({ onClose, currentModelName, dbt_url, branch, editorContent,updateFiles,files,updateFileContentWithoutUnsaved,triggerReload }) {
  const [isLoading, setIsLoading] = useState(true);  // Start with loading state true
  const [isSaving, setIsSaving] = useState(false);
  const [columnTests, setColumnTests] = useState({});
  const [tableTests, setTableTests] = useState({});
  const [showColumnDropdowns, setShowColumnDropdowns] = useState(false);
  const [showTableDropdowns, setShowTableDropdowns] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState('');
  const [selectedColumnTest, setSelectedColumnTest] = useState('');
  const [selectedTableTest, setSelectedTableTest] = useState('');
  const [availableTests, setAvailableTests] = useState({ column: [], table: [] });
  const [selectedColumnTestInputs, setSelectedColumnTestInputs] = useState({});
  const [selectedTableTestInputs, setSelectedTableTestInputs] = useState({});
  const [selectedColumnTestConfig, setSelectedColumnTestConfig] = useState({});
  const [selectedTableTestConfig, setSelectedTableTestConfig] = useState({});
  const [columnTestInputValues, setColumnTestInputValues] = useState({});
  const [tableTestInputValues, setTableTestInputValues] = useState({});
  const [loadingCols, setLoadingCols] = useState(true);


  useEffect(() => {
    fetchData();
  }, [currentModelName, dbt_url, branch, triggerReload]);

  const [columns, setColumns] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    fetchColumnsFromEditor(); // This is now called without awaiting
    try {
      await Promise.all([
        fetchTests(),
        fetchAvailableTests()
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTests = async () => {
    const response = await fetchTestsService(dbt_url, currentModelName, branch);

    if(response) {
      setColumnTests(response.columnTests);
      setTableTests(response.tableTests);
    }
  };

  const fetchAvailableTests = async () => {
    try {
      const response = await fetchAvaialbleTestsService(dbt_url);
      setAvailableTests(response.data);
    } catch (error) {
      throw error;  // Propagate the error
    }
  };

  const fetchColumnsFromEditor = async () => {
    setLoadingCols(true);

    const response = await fetchColumnsFromEditorService(dbt_url, {
      sql: editorContent,
      tabname: getFileName(currentModelName),
      dbt_branch: branch,
      rows:1,
    })

    if(response) {
      setColumns(
        response.data.columns
          .filter((column) => !column.startsWith("_airbyte"))
          .sort((a, b) => a.localeCompare(b))
      );
    }

    setLoadingCols(false);
  };

  
  const handleColumnParamChange = (columnName, testName, paramName, value) => {
    setColumnTests(prevTests => ({
      ...prevTests,
      [columnName]: prevTests[columnName].map(test =>
        test.name === testName ? { ...test, params: { ...test.params, [paramName]: value } } : test
      )
    }));
  };

  const handleTableParamChange = (testName, paramName, value) => {
    setTableTests(prevTests => ({
      ...prevTests,
      [testName]: {
        ...prevTests[testName],
        params: {
          ...prevTests[testName].params,
          [paramName]: value
        }
      }
    }));
  };

  const handleDeleteTest = (testType, columnName, testName) => {
    if (testType === 'column') {
      setColumnTests(prevTests => {
        const updatedTests = { ...prevTests };
        if (updatedTests[columnName]) {
          updatedTests[columnName] = updatedTests[columnName].filter(test => test.name !== testName);
          if (updatedTests[columnName].length === 0) {
            delete updatedTests[columnName];
          }
        }
        return updatedTests;
      });
    } else if (testType === 'table') {
      setTableTests(prevTests => {
        const updatedTests = { ...prevTests };
        delete updatedTests[testName];
        return updatedTests;
      });
    }
  };

  const sortParams = (params) => {
    const sortedParams = {};
    const keys = Object.keys(params).sort((a, b) => {
      if (a.includes('.') && !b.includes('.')) return 1;
      if (!a.includes('.') && b.includes('.')) return -1;
      return 0;
    });
    keys.forEach(key => {
      sortedParams[key] = params[key];
    });
    return sortedParams;
  };

  const renderColumnTests = () => {
    return Object.entries(columnTests).map(([columnName, tests], index, array) => (
      <div key={columnName} className="custom-accordion-item">
        <Accordion className="custom-accordion">
          <Accordion.Item eventKey={columnName}>
            <Accordion.Header className="d-flex align-items-center justify-content-between w-100">
              <div>
              <span className="me-2" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{columnName}</span>
                <span className="text-muted small" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  ({tests.map(test => test.name).join(', ')})
              </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {tests.map((test, testIndex) => (
                <div key={testIndex} className="mb-3 d-flex align-items-center">
                  <Button
                    variant="link"
                    className="p-0 me-2"
                    onClick={() => handleDeleteTest('column', columnName, test.name)}
                  >
                    <Trash2 size={16} className="text-danger" />
                  </Button>
                  <div className="flex-grow-1">
                  <h6 className="mb-0" style={{fontSize: '0.875rem', wordBreak: "break-word", overflowWrap: "break-word"}}>{test.name}</h6>
                  {Object.entries(sortParams(test.params)).length > 0 ? (
                      Object.entries(sortParams(test.params)).map(([paramName, paramValue]) => (
                        <Form.Group key={paramName} className="mb-2 d-flex align-items-center">
                          <Form.Label className="mb-0 me-2 common-label" style={{minWidth: '100px', fontSize: '0.75rem'}}>
                            {paramName}:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            value={paramValue}
                            onChange={(e) => handleColumnParamChange(columnName, test.name, paramName, e.target.value)}
                            className="fixed-width-input"
                          />
                        </Form.Group>
                      ))
                    ) : (
                      <div className="text-muted" style={{fontSize: '0.75rem'}}>No parameters</div>
                    )}
                  </div>
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {index < array.length - 1 && <hr className="my-2" />}
      </div>
    ));
  };

  const renderTableTests = () => {
    return Object.entries(tableTests).map(([testName, test], index, array) => (
      <div key={testName} className="custom-accordion-item">
        <Accordion className="custom-accordion">
          <Accordion.Item eventKey={testName}>
            <Accordion.Header>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex align-items-center">
                  <Button
                    variant="link"
                    className="p-0 me-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteTest('table', null, testName);
                    }}
                  >
                    <Trash2 size={16} className="text-danger" />
                  </Button>
                  <span style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>{testName}</span>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {Object.entries(sortParams(test.params)).length > 0 ? (
                Object.entries(sortParams(test.params)).map(([paramName, paramValue]) => (
                  <Form.Group key={paramName} className="mb-2" style={{ display: "flex", flexDirection: "column" }}>
                    <Form.Label className="mb-1 common-label" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                      {paramName}:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      size="sm"
                      value={paramValue}
                      onChange={(e) => handleTableParamChange(testName, paramName, e.target.value)}
                      className="fixed-width-input w-100"
                      style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                    />
                  </Form.Group>
                ))
              ) : (
                <div className="text-muted" style={{fontSize: '0.75rem'}}>No parameters</div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {index < array.length - 1 && <hr className="my-2" />}
      </div>
    ));
  };

  const handleAddColumnTest = () => {
    setShowColumnDropdowns(!showColumnDropdowns);
    setShowTableDropdowns(false);
  };

  const handleAddTableTest = () => {
    setShowTableDropdowns(!showTableDropdowns);
    setShowColumnDropdowns(false);
  };

  const handleSaveTests = async () => {
    setIsSaving(true);
    
    const response = await handleSaveTestsService(dbt_url, { columnTests, tableTests, currentModelName, branch })

    if(response) {
      // Check if schema.yml is in open files and update if present
      const schemaYmlPath = `${response.data.path}/schema.yml`;

      if (Object.keys(files).includes(schemaYmlPath)) {
          updateFileContentWithoutUnsaved(schemaYmlPath, response.data.schema_yml);
      }
      onClose();
    }
    setIsSaving(false);
  };

  // Function to extract filename from path
  const getFileName = (path) => {
    if (!path) return '';
    const parts = path.split('/');
    const fileNameWithExtension = parts[parts.length - 1];
    return fileNameWithExtension.replace('.sql', '');
  };

  const handleSelectColumnTest = (test) => {
    setSelectedColumnTest(test.name);
    setSelectedColumnTestConfig(test.inputs || {});
    setColumnTestInputValues({}); // Reset input values
  };

  const handleSelectTableTest = (test) => {
    setSelectedTableTest(test.name);
    setSelectedTableTestConfig(test.inputs || {});
    setTableTestInputValues({}); // Reset input values
  };

  const renderInputFields = (config, prefix = '') => {
    const sortedConfig = sortParams(config);
    return Object.entries(sortedConfig).map(([key, value]) => {
      const fullKey = prefix ? `${prefix}.${key}` : key;
      if (Array.isArray(value)) {
        return (
          <div key={fullKey} className="mb-2">
            {value.map((item, index) => (
              <Form.Group key={`${fullKey}.${index}`} className="mb-2 d-flex align-items-center justify-content-between">
                <Form.Label className="mb-0 me-2 common-label">
                  {`${key} (${index + 1}):`}
                </Form.Label>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder={item}
                  onChange={(e) => handleInputChange(`${fullKey}.${index}`, e.target.value)}
                  className="fixed-width-input"
                />
              </Form.Group>
            ))}
          </div>
        );
      } else if (typeof value === 'object' && value !== null) {
        return (
          <div key={fullKey} className="nested-accordion-item">
            <Accordion className="nested-accordion">
              <Accordion.Item eventKey={fullKey}>
                <Accordion.Header>{key}</Accordion.Header>
                <Accordion.Body>
                  {renderInputFields(value, fullKey)}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        );
      } else {
        return (
          <Form.Group key={fullKey} className="mb-2 d-flex align-items-center justify-content-between">
            <Form.Label className="mb-0 me-2 common-label">
              {key}:
            </Form.Label>
            <Form.Control
              type="text"
              size="sm"
              placeholder={value}
              onChange={(e) => handleInputChange(fullKey, e.target.value)}
              className="fixed-width-input"
            />
          </Form.Group>
        );
      }
    });
  };

  const handleInputChange = (key, value) => {
    if (showColumnDropdowns) {
      setColumnTestInputValues(prev => ({...prev, [key]: value}));
    } else if (showTableDropdowns) {
      setTableTestInputValues(prev => ({...prev, [key]: value}));
    }
  };

  // Calculate the count of column tests
  const columnTestCount = Object.values(columnTests).reduce((count, tests) => count + tests.length, 0);

  // Calculate the count of table tests
  const tableTestCount = Object.keys(tableTests).length;

  const handleSaveNewTest = () => {
    if (showColumnDropdowns && selectedColumn && selectedColumnTest) {
      // Check if the column test already exists
      const existingColumnTest = columnTests[selectedColumn]?.find(test => test.name === selectedColumnTest);
      if (existingColumnTest) {
        toast.info('This column test already exists.');
        return;
      }

      const allRequiredInputsFilled = Object.entries(selectedColumnTestConfig).every(([key, value]) => {
        return columnTestInputValues[key] !== undefined && columnTestInputValues[key] !== '' || 
               (typeof value === 'string' && value.toLowerCase().includes('optional') || key === 'config.error_if' || key === 'config.severity' || key === 'config.warn_if');
      });
      if (allRequiredInputsFilled) {
        const filteredInputs = Object.fromEntries(
          Object.entries(columnTestInputValues).filter(([key, value]) => value !== '')
        );
        setColumnTests(prevTests => ({
          ...prevTests,
          [selectedColumn]: [
            ...(prevTests[selectedColumn] || []),
            { name: selectedColumnTest, params: sortParams(filteredInputs) }
          ]
        }));
        setSelectedColumn('');
        setSelectedColumnTest('');
        setColumnTestInputValues({});
        setShowColumnDropdowns(false);
      } else {
        toast.info('Please fill in all required inputs for the column test.');
      }
    } else if (showTableDropdowns && selectedTableTest) {
      // Check if the table test already exists
      if (tableTests[selectedTableTest]) {
        toast.info('This table test already exists.');
        return;
      }

      const allRequiredInputsFilled = Object.entries(selectedTableTestConfig).every(([key, value]) => {
        return tableTestInputValues[key] !== undefined && tableTestInputValues[key] !== '' || 
               (typeof value === 'string' && value.toLowerCase().includes('optional'));
      });
      if (allRequiredInputsFilled) {
        const filteredInputs = Object.fromEntries(
          Object.entries(tableTestInputValues).filter(([key, value]) => value !== '')
        );
        setTableTests(prevTests => ({
          ...prevTests,
          [selectedTableTest]: {
            name: selectedTableTest,
            params: sortParams(filteredInputs)
          }
        }));
        setSelectedTableTest('');
        setTableTestInputValues({});
        setShowTableDropdowns(false);
      } else {
        toast.info('Please fill in all required inputs for the table test.');
      }
    } else {
      toast.error('Please provide all required fields before saving the test.');
    }
  };

  return (
    <ResizableBox
      className={`dragged-box react-resizable sidebar-modal-wrapper`}
      height={window.innerHeight - 50}
      width={window.innerWidth / 3}
      maxConstraints={[window.innerWidth - 65, window.innerHeight - 50]}
      minConstraints={[400, 400]}
      resizeHandles={["w"]}
      id="data-quality-tests-crud-modal"
      style={{
        zIndex: 10000, // Higher z-index to ensure it appears above Robin ChatSidebar
        position: 'relative',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
      }}
    >
      <style>
        {`
          #data-quality-tests-crud-modal .dropdown-menu {
            left: 0 !important;
            right: auto !important;
            transform: none !important;
            top: 100% !important;
            width: 100% !important;
            position: absolute !important;
          }
          
          /* Enhanced fix for ChatSidebar border showing through */
          #data-quality-tests-crud-modal {
            isolation: isolate;
            background: white;
            border-left: 5px solid white !important;
          }
          
          /* Ensure the content section has proper background */
          .schedule-sidebar-content {
            background: white;
            position: relative;
            z-index: 1;
          }
        `}
      </style>
      <section className="schedule-sidebar-content">
        <h2 className="heading-schedule-model">
          <small
            style={{
              fontWeight: 500,
              fontSize: 12,
              border: "2px solid #D0D5DD",
              borderRadius: "7px",
              display: "flex",
              alignItems: "center",
              padding: "12px 15px",
              height: "48px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Icon.ChevronsRight
              color="#438593"
              onClick={onClose}
              role="button"
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
            Data Quality Tests: {getFileName(currentModelName)}
          </small>
        </h2>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <DatajoltSpinner />
          </div>
        ) : (
          <>
            <Accordion defaultActiveKey="0" className="mb-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Column Tests ({columnTestCount})
                </Accordion.Header>
                <Accordion.Body>
                  {renderColumnTests()}
                  
                  {showColumnDropdowns && (
                    <div className="create-test-box mt-3 mb-2">
                      <h6 className="create-test-title">Create new test</h6>
                      <Row>
                        <Col xs={12}>
                          <Dropdown className="position-relative">
                            <Dropdown.Toggle 
                              disabled={loadingCols || columns.length === 0}
                              variant="light" 
                              id="dropdown-column"
                              className="w-100 text-start bg-light text-dark"
                              style={{whiteSpace: "normal"}}
                            >
                              {loadingCols ? 'Loading columns...' : 
                               (columns.length === 0 ? 'Unable to identify columns' : 
                               (selectedColumn || 'Select Column'))}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100" style={{ maxHeight: '300px', overflowY: 'auto', zIndex: 3001, inset: '0px auto auto 0px' }}>
                            {columns.filter(column => column.trim() !== '').map((column, index) => (
                                <Dropdown.Item 
                                  key={index} 
                                  onClick={() => setSelectedColumn(column)}
                                >
                                  {column}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                        <Col xs={12}>
                          <Dropdown className="position-relative">
                            <Dropdown.Toggle 
                              variant="light" 
                              id="dropdown-test"
                              className="w-100 text-start bg-light text-dark"
                              style={{whiteSpace: "normal"}}
                            >
                              {selectedColumnTest || 'Select Test'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100" style={{ zIndex: 3001, inset: '0px auto auto 0px' }}>
                              {availableTests.column.map((test, index) => (
                                <Dropdown.Item 
                                  key={index} 
                                  onClick={() => handleSelectColumnTest(test)}
                                  className="text-wrap"
                                >
                                  {test.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      {Object.keys(selectedColumnTestConfig).length > 0 && (
                        <div className="mt-3">
                          {renderInputFields(selectedColumnTestConfig)}
                        </div>
                      )}
                      <Button
                        variant="success"
                        onClick={handleSaveNewTest}
                        className="mt-3 w-100"
                        disabled={!selectedColumn || !selectedColumnTest || !Object.entries(selectedColumnTestConfig).every(([key, value]) => 
                          key === 'config.error_if' || key === 'config.warn_if' || key === 'config.severity' || key === 'config.severity' ||
                          columnTestInputValues[key] !== undefined && columnTestInputValues[key] !== '' || 
                          (typeof value === 'string' && value.toLowerCase().includes('optional'))
                        )}
                      >
                        Add test
                      </Button>
                    </div>
                  )}
                  
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      variant={showColumnDropdowns ? "secondary" : "primary"}
                      onClick={handleAddColumnTest}
                      className="rounded-circle"
                      style={{ width: '40px', height: '40px', padding: 0 }}
                    >
                      {showColumnDropdowns ? <Icon.X size={20} /> : <Icon.Plus size={20} />}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="1" className="mb-3">
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Table Tests ({tableTestCount})
                </Accordion.Header>
                <Accordion.Body>
                  {renderTableTests()}
                  
                  {showTableDropdowns && (
                    <div className="create-test-box mt-3 mb-2">
                      <h6 className="create-test-title">Create new test</h6>
                      <Row>
                        <Col xs={12}>
                          <Dropdown className="position-relative" style={{zIndex: 3001}}>
                            <Dropdown.Toggle 
                              variant="light" 
                              id="dropdown-table-test"
                              className="w-100 text-start bg-light text-dark"
                              style={{whiteSpace: "normal"}}
                            >
                              {selectedTableTest || 'Select Test'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100" style={{inset: '0px auto auto 0px' }}>
                              {availableTests.table.map((test, index) => (
                                <Dropdown.Item 
                                  key={index} 
                                  onClick={() => handleSelectTableTest(test)}
                                  className="text-wrap"
                                >
                                  {test.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      {Object.keys(selectedTableTestConfig).length > 0 && (
                        <div className="mt-3">
                          {renderInputFields(selectedTableTestConfig)}
                        </div>
                      )}
                      <Button
                        variant="success"
                        onClick={handleSaveNewTest}
                        className="mt-3 w-100"
                        disabled={!selectedTableTest || !Object.entries(selectedTableTestConfig).every(([key, value]) => 
                          tableTestInputValues[key] !== undefined && tableTestInputValues[key] !== '' || 
                          (typeof value === 'string' && value.toLowerCase().includes('optional'))
                        )}
                      >
                        Add test
                      </Button>
                    </div>
                  )}
                  
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      variant={showTableDropdowns ? "secondary" : "primary"}
                      onClick={handleAddTableTest}
                      className="rounded-circle"
                      style={{ width: '40px', height: '40px', padding: 0 }}
                    >
                      {showTableDropdowns ? <Icon.X size={20} /> : <Icon.Plus size={20} />}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        )}
        
        <div className="mt-auto">
          <Button 
            variant="primary" 
            onClick={handleSaveTests}
            className="w-100"
            disabled={isSaving}
          >
            {isSaving ? (
              <>
                Saving...
              </>
            ) : (
              <>
                Save Tests <Icon.Save size={16} className="ms-2" />
              </>
            )}
          </Button>
        </div>
      </section>
    </ResizableBox>
  );
}