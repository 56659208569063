import React from "react";
import { DialogContentText, Typography } from "@mui/material";
import ModalLayout from "./Layout";
import ModalLayoutHeader from "./Layout/Header";
import ModalLayoutContent from "./Layout/Content";
import ModalLayoutFooter from "./Layout/Footer";
import ModalLayoutBtn from "./Layout/Btn";
import { GitBranch } from "react-feather";

/**
 *
 * @param {{
 *  isShown: boolean;
 *  onHide: () => void;
 *  onAction: () => void;
 *  activeBranch: string;
 * }} data
 */
const SwitchBranchModal = ({ isShown, onHide, onAction, activeBranch }) => {
  return (
    <ModalLayout isShown={isShown} onHide={onHide}>
      <ModalLayoutHeader onHide={onHide}>
        <GitBranch />{" "}
        <Typography
          component={"span"}
          fontSize={11}
          fontFamily={"Poppins"}
          fontWeight={700}
        >
          You are switching branch.
        </Typography>
      </ModalLayoutHeader>
      <ModalLayoutContent>
        <DialogContentText
          textAlign={"center"}
          fontSize={11}
          fontFamily={"Poppins"}
          color="#6c757d"
        >
          Switching branch will close
            your open tabs - except for untitled ones -
          from{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {activeBranch}
          </Typography>
          . <br />
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            Your work is saved{" "}
          </Typography>
          and you can always switch back to{" "}
          <Typography
            component={"span"}
            fontSize={11}
            fontFamily={"Poppins"}
            fontWeight={700}
          >
            {activeBranch}
          </Typography>{" "}
          to retrieve queries.
        </DialogContentText>
      </ModalLayoutContent>
      <ModalLayoutFooter>
        <ModalLayoutBtn color="primary" onClick={onAction}>
          OK
        </ModalLayoutBtn>
      </ModalLayoutFooter>
    </ModalLayout>
  );
};

export default SwitchBranchModal;
